@use 'sass:math';

@mixin media($key) {
    @if $key == 'd' {
        @content;
    }
    @else if map-has-key($breakpoints, $key) {
        @media (min-width: map-get($breakpoints, $key)) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin media-max($key) {
    @if $key == 'd' {
        @content;
    }
    @else if map-has-key($breakpoints, $key) {
        @media (max-width: map-get($breakpoints, $key)-1) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin media-min-max($minKey, $maxKey) {
    @if $minKey == 'd' or $maxKey == 'd' {
        @content;
    }
    @else if map-has-key($breakpoints, $minKey) and map-has-key($breakpoints, $maxKey) {
        @media (min-width: map-get($breakpoints, $minKey)) and (max-width: map-get($breakpoints, $maxKey)-1) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin clearfix() {
    &::after,
    &::before {
        content: '';
        display: table;
        clear: both;
        width: 100%;
    }
}

@mixin href-external() {
    &:not([href^="mailto:"]):not([href^="tel:"]):not([href=""]):not([href^="#"]):not([href^="/"]):not([href^="http://arkitekt.se"]):not([href^="https://arkitekt.se"]):not([href*="www.arkitekt.se"]):not([href*="stage.arkitekt.se"]):not([href*="beta.arkitekt.se"]):not([href*="dev.arkitekt.se"]) {
        @content;
    }
}

@mixin href-download() {
    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".docx"],
    &[href$=".ppt"],
    &[href$=".pptx"],
    &[href$=".xls"],
    &[href$=".xlsx"],
    &[href$=".odt"],
    &[href$=".eps"],
    &[href$=".zip"] {
        @content;
    }
}

/*
 * Add option for className and classNamePrefix to react-select, and then include in class
 *
 * e.g. className Input__ReactSelect, and classNamePrefix Input__ReactSelect
 *      include in scss:
 *      .Input {
 *          &__ReactSelect {
 *              @include react-select;
 *          }
 *      }
 */
@mixin react-select($root) {
    @extend %form__react-select;
    
    &__control {
        @extend %form__react-select__control;

        &--is-focused {
            @extend %form__react-select__control--is-focused;
        }
    }

    &__value-container {
        @extend %form__react-select__value-container;
    }

    &__placeholder {
        @extend %form__react-select__placeholder;
    }

    &__single-value {
        @extend %form__react-select__single-value;
    }

    &__multi-value {
        @extend %form__react-select__multi-value;
        
        &__label {
            @extend %form__react-select__multi-value__label;
        }
        
        &__remove {
            @extend %form__react-select__multi-value__remove;
        }
    }

    &__input {
        @extend %form__react-select__input;
    }

    &__value-container--is-multi#{$root}__value-container--has-value #{$root}__input {
        @extend %form__react-select__input--is-multi-has-value;
    }

    &__indicators {
        @extend %form__react-select__indicators;
    }

    &__indicator {
        @extend %form__react-select__indicator;
    }

    &__indicator-separator {
        @extend %form__react-select__indicator-separator;
    }

    &__clear-indicator {
        @extend %form__react-select__clear-indicator;
    }

    &__dropdown-indicator {
        @extend %form__react-select__dropdown-indicator;
    }

    &__menu {
        @extend %form__react-select__menu;
    }

    &__option {
        @extend %form__react-select__option;

        &:hover,
        &--is-focused {
            &:not(.form-select__option--is-selected) {
                @extend %form__react-select__option--is-focused;
            }
        }

        &--is-selected {
            @extend %form__react-select__option--is-selected;
        }
    }
}

// For wrapping elements in the main grid
// Usage: @include wrap;
@mixin wrap($exclude: ()) {
    @if not contains($exclude, d) {
        margin-left: $gutter;
        margin-right: $gutter;
    }
    @if not contains($exclude, SL) {
        @include media(SL) {
            max-width: (map-get($maxwidths, SL));
            margin-left: auto;
            margin-right: auto;
        }
    }
    @if not contains($exclude, M) {
        @include media(M) {
            max-width: (map-get($maxwidths, M));
            margin-left: auto;
            margin-right: auto;
        }
    }
    @if not contains($exclude, ML) {
        @include media(ML) {
            max-width: (map-get($maxwidths, ML));
            margin-left: auto;
            margin-right: auto;
        }
    }
    @if not contains($exclude, L) {
        @include media(L) {
            max-width: (map-get($maxwidths, L));
            margin-left: auto;
            margin-right: auto;
        }
    }
    @if not contains($exclude, XL) {
        @include media(XL) {
            max-width: (map-get($maxwidths, XL));
            margin-left: auto;
            margin-right: auto;
        }
    }
    @if not contains($exclude, XXL) {
        @include media(XXL) {
            max-width: (map-get($maxwidths, XXL));
            margin-left: auto;
            margin-right: auto;
        }
    }
}

// For setting font-size and line-height on elements defined by variabels, including media queries
// Usage: @include textstyle(h1);
@mixin textstyle($key) {

    // Loop through text map and set font-size and line-height for each media query
    @if map-has-key($textstyles, $key) {
        $map: map-get($textstyles, $key);

        @each $media, $style in $map {
            @include media($media) {
                @if map-has-key($style, font-size) and map-has-key($style, line-height) {
                    $font-size: map-get($style, font-size);
                    $line-height: map-get($style, line-height);

                    font-size: #{math.div($font-size, 10)}rem;
                    line-height: #{math.div(round(math.div($line-height, $font-size) * 100), 100)};

                    @if map-has-key($style, font-weight) {
                        font-weight: map-get($style, font-weight);
                    }
                    @if map-has-key($style, letter-spacing) {
                        letter-spacing: map-get($style, letter-spacing);
                    }
                }
            }
        }
    }

    @if ($key == h1 or $key == h2 or $key == h3 or $key == h4 or $key == h5 or $key == h6) {
        color: $heading-color;
        font-family: $heading-font;
    }
    @else if $key == label {
        text-transform: uppercase;
    }
}


// For using buttons, pass in multiple parameters for different settings
// Usage: @include button(primary);
// Usage: @include button(primary full);
// Usage: @include button(secondary);
// Usage: @include button(tertiary);
// Usage: @include button(tertiary external);
// Usage: @include button(tertiary download);
// Usage: @include button(filter);
// Usage: @include button(filter active);
// Usage: @include button(link);
@mixin button($list: (), $excludeDefault: false) {
    $isPrimary: contains($list, primary);
    $isSecondary: contains($list, secondary);
    $isTertiary: contains($list, tertiary);
    $isQuaternary: contains($list, quaternary);
    $isSmall: contains($list, small);
    $isFilter: contains($list, filter);
    $isLink: contains($list, link);
    $isFull: contains($list, full);

    $isDisabled: contains($list, disabled);
    $excludeIcon: contains($list, exclude-icon);
    $isIcon: (not $excludeIcon or contains($list, external) or contains($list, download));
    $isButton: not $isLink and not $isDisabled;
    $isHover: contains($list, hover);
    $isFocus: contains($list, focus);
    $isActive: contains($list, active);

    @if $isDisabled {
        color: $grey-40 !important;

        @if $isPrimary {
            background: $grey-20 !important;
            box-shadow: none !important;
            cursor: initial;

            &::before {
                opacity: 0 !important;
            }

            &::after {
                transform: none !important;
                background-image: url('#{$assetsPath}img/arrowRight--grey.svg');
            }
        }
    }

    @if $isButton {
        @if not $excludeDefault {
            position: relative;
            display: inline-block;
            font-family: $base-font;
            border: none;
            border-radius: 0px;
            background: transparent;
            box-shadow: none;
            overflow: hidden;
            cursor: pointer;
            -webkit-appearance: none;
        }

        @if $isFull {
            width: 100%;
            display: block;
        }

        @if $isPrimary {
            @if not $excludeDefault {
                @include textstyle(button);
                padding: 18px 48px 19px 24px;
                min-width: 193px;
                text-align: left;
                color: white;
                background: $purple;
                transform-style: preserve-3d;
                transition: background $transition, box-shadow $transition;
                outline: none;

                :global(.Theme--Arkitekturgalan) & {
                    background: $arkitekturgalan-button-primary;
                }

                @include pseudo-icon(arrowRight--white, after, absolute);

                &::after {
                    height: 14px;
                    right: 16px;
                    transition: transform $transition;
                }

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                    background: rgba(black, .1);
                    opacity: 0;
                    transform: translate(-50%, -50%);
                    transition: opacity $transition;
                    z-index: -1;
                }

                &:not([disabled]) {
                    &:hover {
                        @include button(primary hover, true);
                    }

                    &:active,
                    &:focus {
                        @include button(primary focus, true);
                    }
                }

                &[disabled] {
                    @include button(primary disabled);
                }
            }

            @if not $isIcon {
                padding-right: 24px !important;

                &::after {
                    display: none !important;
                }
            } @else {
                // Icons placed after
                @if contains($list, external) {
                    @include pseudo-icon(external--white, after, absolute);

                    &::after {
                        transform: none !important;
                    }
                }
                @if contains($list, download) {
                    @include pseudo-icon(download--white, after, absolute);

                    &::after {
                        transform: none !important;
                    }
                }
            }

            @if $isHover {
                color: white;
                background: $purple-60;
                box-shadow: 0 2px 16px 0 rgba(black, .24);

                :global(.Theme--Arkitekturgalan) & {
                    background: $arkitekturgalan-button-primary-hover;
                }
            }

            @if $isFocus {
                background: $purple-60;
                box-shadow: none;

                &::before {
                    opacity: 1;
                }

                :global(.Theme--Arkitekturgalan) & {
                    background: $arkitekturgalan-button-primary-hover;
                }
            }

            @if $isHover or $isFocus { 
                &::after {
                    transform: translateX(8px);
                }
            }

            @if $isFull {
                max-width: none;
            }

            @if $isSmall {
                @include textstyle(button-small);
                min-width: 0;
                padding: 12px 20px 14px;
            }
        }
        @else if $isSecondary {
            @if not $excludeDefault {
                @include textstyle(button);
                padding: 37px 80px 38px 40px;
                max-width: 618px;
                text-align: left;
                color: $purple;
                border: 1px solid $purple;
                background: white;
                transform-style: preserve-3d;
                outline: none;
                transition: background $transition, box-shadow $transition;

                @include pseudo-icon(arrowRight--purple, after, absolute);

                :global(.Theme--Arkitekturgalan) & {
                    color: $arkitekturgalan-button-primary;
                    border-color: $arkitekturgalan-button-primary;

                    @include pseudo-icon(arrowRight--arkitekturgalan-blue, after, absolute);
                }

                &::after {
                    height: 19px;
                    right: 34px;
                    transition: transform $transition;
                }

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 126px;
                    height: 126px;
                    border-radius: 50%;
                    background: $purple-20;
                    opacity: 0;
                    transform: translate(-50%, -50%);
                    transition: opacity $transition;
                    z-index: -1;

                    :global(.Theme--Arkitekturgalan) & {
                        background: rgba($arkitekturgalan-button-primary, .3);
                    }
                }

                &:not([disabled]) {
                    &:hover {
                        @include button(secondary hover, true);
                    }

                    &:active,
                    &:focus {
                        @include button(secondary focus, true);
                    }
                }

                &[disabled] {
                    @include button(disabled);
                }
            }

            @if $isSmall {
                @include textstyle(button-small);

                padding: 11px 35px 12px 14px;

                &::after {
                    height: 14px;
                    right: 8px;
                }
            }

            @if not $isIcon {
                padding-right: 40px !important;

                &::after {
                    display: none !important;
                }
            }

            @if $isHover {
                color: $purple;
                background: $purple-10;
                box-shadow: 0 2px 16px 0 rgba(black, .24);

                :global(.Theme--Arkitekturgalan) & {
                    color: $arkitekturgalan-button-primary;
                    background: rgba($arkitekturgalan-button-primary, .05);
                }
            }

            @if $isFocus {
                background: $purple-10;
                box-shadow: none;

                :global(.Theme--Arkitekturgalan) & {
                    background: rgba($arkitekturgalan-button-primary, .05);
                }

                &::before {
                    opacity: 1;
                }
            }

            @if $isHover or $isFocus {
                &::after {
                    transform: translateX(8px);
                }
            }

            @if $isFull {
                max-width: none;
            }
        }
        @else if $isTertiary {
            @if not $excludeDefault {
                @include textstyle(button);
                padding: 13px 15px 14px;
                text-align: left;
                color: $purple;
                line-height: 1.5;
                background: transparent;
                outline: none;
                transition: background $transition;

                :global(.Theme--Arkitekturgalan) & {
                    color: $arkitekturgalan-button-primary;
                }

                &:not([disabled]) {
                    &:hover {
                        @include button(tertiary hover, true);
                    }
                    &:active,
                    &:focus {
                        @include button(tertiary focus, true);
                    }
                }

                &[disabled] {
                    @include button(disabled);
                }
            }

            @if $isIcon {
                // Icons placed before
                @if contains($list, download) {
                    @include pseudo-icon(download--purple, before, relative);

                    :global(.Theme--Arkitekturgalan) & {
                        @include pseudo-icon(download--arkitekturgalan-blue, before, relative);
                    }

                    &::before {
                        margin-right: 10px;
                        margin-bottom: -3px;
                    }
                }

                // Icons placed after
                @if contains($list, external) {
                    @include pseudo-icon(external--purple, after, relative);

                    &::before {
                        display: none !important; // If both download and external match
                    }

                    :global(.Theme--Arkitekturgalan) & {
                        @include pseudo-icon(external--arkitekturgalan-blue, after, relative);
                    }

                    &::after {
                        margin-left: 10px;
                        margin-bottom: -3px;
                    }
                }
            }

            @if $isFocus or $isHover {
                color: $purple;
                background: $purple-20;

                :global(.Theme--Arkitekturgalan) & {
                    color: $arkitekturgalan-button-primary;
                    background: $arkitekturgalan-button-primary-hover;
                }
            }
        }
        @else if $isQuaternary {
            @if not $excludeDefault {
                @include textstyle(button);
                padding: 18px 48px 19px 24px;
                min-width: 193px;
                text-align: left;
                color: $purple;
                background: white;
                transform-style: preserve-3d;
                transition: background $transition, box-shadow $transition, color $transition;
                outline: none;

                @include pseudo-icon(arrowRight--purple, after, absolute);

                :global(.Theme--Arkitekturgalan) & {
                    color: $arkitekturgalan-button-primary;

                    @include pseudo-icon(arrowRight--arkitekturgalan-blue, after, absolute);
                }

                &::after {
                    height: 14px;
                    right: 16px;
                    transition: transform $transition;
                }

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                    background: rgba(black, .1);
                    opacity: 0;
                    transform: translate(-50%, -50%);
                    transition: opacity $transition;
                    z-index: -1;
                }

                &:not([disabled]) {
                    &:hover {
                        @include button(quaternary hover, true);
                    }
                    &:active,
                    &:focus {
                        @include button(quaternary focus, true);
                    }
                }

                &[disabled] {
                    @include button(disabled);
                }
            }

            @if not $isIcon {
                padding-right: 24px !important;

                &::after {
                    display: none !important;
                }
            }

            @if $isHover {
                color: white;
                background: $purple-60;
                box-shadow: 0 2px 16px 0 rgba(black, .24);

                :global(.Theme--Arkitekturgalan) & {
                    color: black;
                    background: $arkitekturgalan-button-primary-hover;
                }
            }

            @if $isFocus {
                color: white;
                background: $purple-60;
                box-shadow: none;

                :global(.Theme--Arkitekturgalan) & {
                    color: black;
                    background: $arkitekturgalan-button-primary-hover;
                }

                &::before {
                    opacity: 1;
                }
            }

            @if $isHover or $isFocus {
                &::after {
                    background-image: url('#{$assetsPath}img/arrowRight--white.svg');
                    transform: translateX(8px);

                    :global(.Theme--Arkitekturgalan) & {
                        background-image: url('#{$assetsPath}img/arrowRight.svg');
                    }
                }
            }
        }
        @else if $isFilter {
            @if not $excludeDefault {
                @include textstyle(body-regular);
                padding: 7px 16px;
                text-align: left;
                color: $grey;
                border: 1px solid $grey-30;
                background: white;
                outline: none;
                transition: background $transition, border-color $transition;

                &:hover {
                    @include button(filter hover, true);
                }
                &:active,
                &:focus {
                    @include button(filter focus, true);
                }

                @include media(M) {
                    padding: 11px 24px;
                }
            }

            @if $isFocus or $isHover {
                border-color: $grey-35;
            }

            @if $isActive {
                border-color: $purple !important;
                background: $purple-10 !important;

                :global(.Theme--Arkitekturgalan) & {
                    border-color: $arkitekturgalan-button-primary !important;
                    background: rgba($arkitekturgalan-button-primary, .05) !important;
                }
            }
        }
    }
    @else if $isLink {
        @if not $excludeDefault {
            position: relative;
            display: inline; // Must be inline for the border to act as underline when text is wrapped
            color: $grey-70;
            border-bottom: 1px solid $grey-70;
            transition: color $transition, border-color $transition;
            cursor: pointer;

            &:hover {
                @include button(link hover, true);
            }
        }

        @if $isHover {
            color: $primary-color;
            border-color: $primary-color;

            &::before {
                opacity: 0;
            }

            &::after {
                opacity: 1;
            }

            :global(.Theme--Arkitekturgalan) & {
                color: $arkitekturgalan-button-primary;
                border-color: $arkitekturgalan-button-primary;
            }
        }

        // For links that can not be inline and aren't too long
        @if $isFull {
            display: inline-block;
            border: none;

            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: 1px;
                left: 0;
                right: 0;
                margin: auto;
                height: 1px;
                background: $grey-70;
                transition: background $transition;
            }

            &:hover {
                color: $primary-color;

                &::after {
                    background: $primary-color;
                }

                :global(.Theme--Arkitekturgalan) & {
                    color: $arkitekturgalan-button-primary;

                    &::after {
                        background: $arkitekturgalan-button-primary;
                    }
                }
            }
        }
    }
}

// Shorthand for adding pseudo elements, pass placement and/or position
// If position is relative the icon will be inline block and positioned depending on placement
// Usage: @include pseudo(download);
// Usage: @include pseudo(arrow, after, absolute);
@mixin pseudo-icon($icon, $placement: before, $position: relative) {
    &::#{$placement} {
        content: '';
        position: $position;
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('#{$assetsPath}img/#{$icon}.svg') no-repeat center center;
        background-size: contain;

        @if $position == absolute {
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
}

@mixin loader($name, $scale: 1, $color: $accent-color, $animationDelay: .16s) {
    $baseHeight: 8px * $scale;
    $lineExpand: 6px * $scale;
    $lineWidth: 1px * $scale;
    $lineSpacing: 2px * $scale;

    position: relative;
    height: #{$baseHeight + $lineExpand * 2};
    width: #{$lineSpacing * 2 + $lineWidth * 3};

    span:not(:global(.sr-only)) {
        display: block;
        position: relative;
        top: $lineExpand;
        margin: 0 #{$lineSpacing + $lineWidth};
        transform: translateZ(0);

        &,
        &::before,
        &::after {
            width: $lineWidth;
            height: $baseHeight;
            color: $color;
            background: $color;
            animation: loader 1s infinite ease-in-out;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
        }

        &::before {
          left: -#{$lineSpacing + $lineWidth};
          animation-delay: -#{$animationDelay};
        }

        &:after {
            left: #{$lineSpacing + $lineWidth};
            animation-delay: #{$animationDelay};
        }
    }

    @keyframes loader {
        0%,
        80%,
        100% {
            box-shadow: 0 0;
            height: $baseHeight;
        }
        40% {
            box-shadow: 0 -#{$lineExpand};
            height: #{$baseHeight + $lineExpand};
        }
    }

}
