$assetsPath: '../../public/' !default;
$fontsPath: '#{$assetsPath}fonts/';

$gutter: 16px;
$gutterM: 24px; // Used for media query above M

// Grid and site widths
$maxwidths: (
    // S: 320px,
    SL: 500px - ($gutter * 2),
    M: 720px,
    ML: 808px,
    L: 1140px,
    XL: 1280px,
    XXL: 1440px,
    content: 816px,
    text: 520px,
    article: 618px,
    longread: 665px,
);
$breakpoints: (
    S: 375px,
    SL: 500px, // Landscape
    M: 768px,
    ML: 1024px, // Landscape
    L: 1280px,
    XL: 1440px,
    XXL: 1680px,
    wp-sm: 601px,
    wp-md: 783px,
);

// Define breakpoint variables for specific functionality
$mobile-menu: S;

// Measurments
$wp-admin-bar-height: 46px;
$wp-admin-bar-height-md: 32px;

$transition: .3s ease-in-out;

$z-HeaderSubShadow: 0;
$z-ArticleContent: 1;
$z-Form: 1;
$z-Card: 1;
$z-HeaderLogoLink: 1;
$z-ServiceMenu: 1;
$z-RichtextImageFull: 1;
$z-Breadcrumbs: 10;
$z-LongreadSidebarMobile: 20;
$z-LongreadTableOfContentsMobile: 21;
$z-Header: 100;
$z-HeaderSub: 110;
$z-MenuMobile: 1000;
$z-Cookie: 1500;
// Modals should always be highest
$z-FormOverlay: 1900;
$z-GalleryModal: 2000;

// Colors
$grey: #5E5E5E;
$grey-5: #fbfbfb;
$grey-10: #F5F5F5;
$grey-15: #EDEDEF;
$grey-20: #E2E2E2;
$grey-30: #D4D4D4;
$grey-32: #b5b8b0;
$grey-35: #898989;
$grey-40: #696969;
$grey-60: #4C4C4C;
$grey-70: #313131;

$purple: #9933FF;
$purple-10: #f5ebff;
$purple-15: #FAF5FF;
$purple-20: #EBD6FF;
$purple-40: #D6B4FF;
$purple-50: #9933ff;
$purple-60: #822BD9;

$salmon: #FF7775;
$salmon-30: #FFE0DF;
$salmon-40: #FFBBBA;
$salmon-45: #FF9B99;

$blue: #0084FF;
$blue-40: #99CEFF;
$blue-30: #CCE6FF;

$green: #30D2A9;
$green-40: #ACEDDD;
$green-30: #D6F6EE;

$red: #d62220;
$complementary-color: #e65d39;

$yellow: #FDFF7D;

// Original for 2022
$arkitekturgalan-2022-blue: #096e88; // Breadcrumbs, buttons, label on cards
$arkitekturgalan-2022-blue-60: #055f76; // Hover on links and buttons
$arkitekturgalan-2022-orange: #e9511e; // Parallax shapes, icons, quotations, logo
$arkitekturgalan-2022-orange-40: #f29778; // Not used?
$arkitekturgalan-2022-orange-30: #fbdcd2; // Slick slider buttons och emphasis
$arkitekturgalan-2022-orange-10: #fcede8; // Background color for site
$arkitekturgalan-2022-red: #ad2534; // Only as graphic in images

// New for 2023
$arkitekturgalan-2023-red: #d93d54;
$arkitekturgalan-2023-red-contrast: #db5197;
$arkitekturgalan-2023-yellow: #ecab03;
$arkitekturgalan-2023-yellow-contrast: #F3DBB2;
$arkitekturgalan-2023-lime: #d7c828;
$arkitekturgalan-2023-lime-contrast: #efe3a5;
$arkitekturgalan-2023-green: #348f41;
$arkitekturgalan-2023-green-contrast: #c7c89c;
$arkitekturgalan-2023-blue: #1d71b8;
$arkitekturgalan-2023-blue-contrast: #acd4ea;

$arkitekturgalan-primary: $arkitekturgalan-2023-yellow; // Parallax shapes, icons, quotations, loader
$arkitekturgalan-secondary: $arkitekturgalan-2023-yellow-contrast; // Slick slider buttons
$arkitekturgalan-link: black; // Breadcrumbs, label on cards
$arkitekturgalan-link-hover: rgba(black, .7); // Hover on links
$arkitekturgalan-button-primary: black; // Buttons
$arkitekturgalan-button-primary-hover: $arkitekturgalan-primary; // Hover on buttons
$arkitekturgalan-slider-dot-inactive: rgba(black, .5);
$arkitekturgalan-slider-dot-active: black;
$arkitekturgalan-slider-dot-hover: black;
$arkitekturgalan-slider-dot-disabled: rgba(black, .3);
$arkitekturgalan-emphasis: $arkitekturgalan-primary; // Emphasis background
$arkitekturgalan-emphasis-color: black; // Emphasis text color
$arkitekturgalan-bg: $arkitekturgalan-secondary; // Background color for site

$base-color: $grey;
$heading-color: black;

$primary-color: $purple;
$accent-color: $salmon;

$success-color: $green;
$error-color: $red;

// Font families
$base-font: #{'Futura LT', Arial, sans-serif};
$heading-font: #{'Futura LT', Arial, sans-serif};

// Font weights
$light: 300;
$regular: 400;
$book: 400;
$medium: 500;
$bold: 700;
$heavy: 900;

// No other font sizes are needed
// Font sizes and line heights as defined by styleguide in pixels
$textstyles: (
    h1: (
        d: (font-size: 34, line-height: 38, font-weight: $heavy),
        L: (font-size: 56, line-height: 64, font-weight: $heavy),
    ),
    h2: (
        d: (font-size: 22, line-height: 32, font-weight: $heavy, letter-spacing: .2px),
        L: (font-size: 30, line-height: 40),
    ),
    h3: (
        d: (font-size: 18, line-height: 27, font-weight: $heavy, letter-spacing: .2px),
        L: (font-size: 22, line-height: 32),
    ),
    h4: (
        d: (font-size: 16, line-height: 24, font-weight: $heavy, letter-spacing: .2px),
        L: (font-size: 18, line-height: 27),
    ),
    h5: (
        d: (font-size: 16, line-height: 24, font-weight: $medium, letter-spacing: .2px),
    ),
    button: (
        d: (font-size: 18, line-height: 20, font-weight: $heavy, letter-spacing: 1px),
    ),
    button-small: (
        d: (font-size: 14, line-height: 16, font-weight: $heavy, letter-spacing: 1px),
    ),
    preamble: (
        d: (font-size: 20, line-height: 29, font-weight: $book, letter-spacing: .2px),
    ),
    body-regular: (
        d: (font-size: 18, line-height: 28, font-weight: $book, letter-spacing: .2px),
    ),
    body-small: (
        d: (font-size: 14, line-height: 20, font-weight: $book, letter-spacing: .2px),
    ),
    card-text: (
        d: (font-size: 14, line-height: 20, font-weight: $book, letter-spacing: .2px),
        L: (font-size: 18, line-height: 28, font-weight: $book, letter-spacing: .2px),
    ),
    navigation-titles: (
        d: (font-size: 16, line-height: 18, font-weight: $book, letter-spacing: .5px),
    ),
    navigation-sub-titles: (
        d: (font-size: 12, line-height: 14, font-weight: $book, letter-spacing: .3px),
    ),
    navigation-breadcrumbs: (
        d: (font-size: 10, line-height: 14, font-weight: $book, letter-spacing: .3px),
    ),
    label: (
        d: (font-size: 12, line-height: 14, font-weight: $heavy, letter-spacing: 2px),
    ),
    helptext: (
        d: (font-size: 12, line-height: 16, font-weight: $book, letter-spacing: .3px),
    ),
);
