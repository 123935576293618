@import 'Styles/includes';

.NotFound {
    $root: &;

    padding-top: 64px;
    padding-bottom: 207px;

    @include media(M) {
        padding-top: 104px;
        padding-bottom: 139px;
    }

    &__ParallaxShape {
        &:first-child {
            right: 71%;
            left: auto;
            bottom: 260px;

            @include media(M) {
                bottom: 100px;
            }
        }

        &:last-child {
            top: 200px;
            bottom: auto;
            left: 60%;
            right: auto;

            @include media(M) {
                left: 68%;
                top: 100px;
            }
        }
    }

    &__Wrap {
        @include wrap;
    }

    &__Content {
        max-width: map-get($maxwidths, text);
        margin: 0 auto;
    }

    &__Title {
        @include textstyle(h1);
        margin-bottom: 16px;
    }

    &__SubTitle {
        @include textstyle(h3);
        margin-bottom: 8px;
    }

    &__Text {
        @include textstyle(body-regular);
    }

    &__Button {
        @include button(primary full);

        margin-top: 32px;
        max-width: 256px;
    }

    &__SearchForm {
        margin-top: 32px;
    }
}
